import { ICountryCode } from '../../interfaces/IBrands';
import { __ } from '../../helpers/TranslationService';

const getTranslatedWeekdaysShort = (): string[] => [
    __('Sun', 'dreamlines'),
    __('Mon', 'dreamlines'),
    __('Tue', 'dreamlines'),
    __('Wed', 'dreamlines'),
    __('Thu', 'dreamlines'),
    __('Fri', 'dreamlines'),
    __('Sat', 'dreamlines'),
];

const getTranslatedMonths = (): string[] => [
    __('January', 'dreamlines'),
    __('February', 'dreamlines'),
    __('March', 'dreamlines'),
    __('April', 'dreamlines'),
    __('May', 'dreamlines'),
    __('June', 'dreamlines'),
    __('July', 'dreamlines'),
    __('August', 'dreamlines'),
    __('September', 'dreamlines'),
    __('October', 'dreamlines'),
    __('November', 'dreamlines'),
    __('December', 'dreamlines'),
];

const getDateFormatByLocale = (locale: ICountryCode) => {
    return 'dd.MM.yyyy';
};
export { getTranslatedWeekdaysShort, getTranslatedMonths, getDateFormatByLocale };
