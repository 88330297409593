import React from 'react';
import { config } from '../../config';

export const XIcon = (props: any) => (
    <img
        className="calendarIcon calendarXIcon"
        {...props}
        alt="Close"
        src={`${config.images}/common/x_icon.svg`}
    />
);

export const StyledArrowLeft = (props: any) => (
    <img
        className="calendarIcon calendarArrowIcon calendarArrowLeftIcon"
        {...props}
        alt="Arrow Left"
        src={`${config.images}/common/arrow.svg`}
    />
);

export const StyledArrowRight = (props: any) => (
    <img
        className="calendarIcon calendarArrowIcon calendarArrowRightIcon"
        {...props}
        alt="Arrow Left"
        src={`${config.images}/common/arrow.svg`}
    />
);

export const StyledXIcon = XIcon;
