import React from 'react';
import { StyledArrowLeft, StyledArrowRight } from '../calendar.style';

export const Navbar = ({ onPreviousClick, onNextClick, className }: any) => {
    return (
        <div className={className}>
            <StyledArrowLeft onClick={() => onPreviousClick()} />
            <StyledArrowRight onClick={() => onNextClick()} />
        </div>
    );
};
