import differenceInMonths from 'date-fns/differenceInMonths';
import dateFnsFormat from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import React, { useCallback } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { config } from '../../config';
import { getAbsoluteUtcDate } from '../../helpers/date';
import { __ } from '../../helpers/TranslationService';
import { StyledXIcon } from './calendar.style';
import {
    getDateFormatByLocale,
    getTranslatedMonths,
    getTranslatedWeekdaysShort,
} from './localization';
import { Navbar } from './navbar/navbar';
import { dateTypeEventMap } from './helpers';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';

export type Dates = 'startDate' | 'endDate' | null | undefined;

const DATE_TYPES = {
    startDate: 'startDate',
    endDate: 'endDate',
};
interface IProps {
    startDate?: Date;
    endDate?: Date;
    pageType?: string;
    keepFocus?: boolean;
    onDateChange?: ([startDate, endDate]: [number, number]) => void;
    numberOfMonths?: number;
    hideSeparator?: boolean;
    showOverlay?: boolean;
    focusedInput?: Dates;
    isMobile?: boolean;
    focusIdetifier?: boolean;
    countryCode: string;
}

const Calendar = (props: IProps) => {
    const [startDate, setStartDate] = React.useState<Date | undefined>(props.startDate);
    const [endDate, setEndDate] = React.useState<Date | undefined>(props.endDate);
    const [focusedInput, setFocusedInput] = React.useState<Dates>();

    const localeConfig: any = { code: props.countryCode };

    const endDatePickerEl = React.useRef<any>();
    const startDatePickerEl = React.useRef<any>();
    const typeOfDate = React.useRef<Dates>();

    const setDurationFilter = useCallback(
        (start, end) => {
            props.onDateChange?.([getAbsoluteUtcDate(start), getAbsoluteUtcDate(end)]);
        },
        [startDate, endDate],
    );

    React.useEffect(() => {
        if (isAfter(startDate, endDate)) {
            handleDateAfter(typeOfDate.current);
            return;
        }

        if (typeOfDate.current && props.pageType) {
            eventTracker.trackG4Event('search_filters', {
                page_type: props.pageType,
                event_type: dateTypeEventMap[typeOfDate.current],
            });
        }
    }, [startDate, endDate]);

    React.useEffect(() => {
        setStartDate(props.startDate ?? undefined);
        setEndDate(props.endDate ?? undefined);
    }, [props.startDate, props.endDate]);

    //Using the local state focusInput for animation.
    React.useEffect(() => setFocusedInput(props.focusedInput), [props.focusedInput]);

    React.useEffect(() => {
        if (!startDate) {
            return;
        }
        if (endDate && differenceInMonths(endDate, startDate) < 2) {
            endDatePickerEl?.current?.getDayPicker()?.showMonth(startDate);
        }
    }, [startDate, endDate]);

    React.useEffect(() => {
        if (props.focusedInput === DATE_TYPES.startDate) {
            startDatePickerEl.current?.input.focus();
        }
        if (props.focusedInput === DATE_TYPES.endDate) {
            endDatePickerEl.current?.input.focus();
        }
    }, [props.focusedInput, startDatePickerEl, endDatePickerEl]);

    const modifiers = { start: startDate, end: endDate };

    const handleDateAfter = (type: Dates) => {
        if (type === DATE_TYPES.startDate) {
            setEndDate(undefined);
        }
        if (type === DATE_TYPES.endDate) {
            setStartDate(undefined);
        }
    };

    const handleDateChange = (date: Date | undefined, type: string) => {
        if (type === DATE_TYPES.startDate) {
            setStartDate(date);
        }
        if (type === DATE_TYPES.endDate) {
            setEndDate(date);
        }
    };
    //Format the date in the input field.
    const formatDate = (date: number | Date, format: string) => {
        return dateFnsFormat(date, format);
    };

    const handleResetDate = (date: Date | undefined, type: string) => {
        setFocusedInput(null);
        handleDateChange(date, type);
        const currentStartDate = type === DATE_TYPES.startDate ? date : startDate;
        const currentEndDate = type === DATE_TYPES.endDate ? date : endDate;

        props.onDateChange([
            getAbsoluteUtcDate(currentStartDate),
            getAbsoluteUtcDate(currentEndDate),
        ]);
    };

    const handleFocusChange = (focusedInput: Dates) => {
        typeOfDate.current = focusedInput;
        setFocusedInput(focusedInput);
    };

    const getFocusStyle = (field: string) => {
        if (focusedInput && field === focusedInput) {
            return { boxShadow: 'inset 0px -3px 0px 0px rgba(0,101,152,1)' };
        }
        return {};
    };

    const dayPickerLocaleSettings = {
        locale: localeConfig.code || 'en',
        months: getTranslatedMonths(),
        weekdaysShort: getTranslatedWeekdaysShort(),
        firstDayOfWeek: 1,
    };
    const dateFormat = getDateFormatByLocale(localeConfig.code);

    return (
        <div
            className={`calendarWrapper${
                props.focusIdetifier && focusedInput ? ` open-${focusedInput}` : ''
            } onlyTabletUp`}
        >
            <div>
                {startDate && (
                    <StyledXIcon onClick={() => handleResetDate(undefined, DATE_TYPES.startDate)} />
                )}
                <DayPickerInput
                    ref={startDatePickerEl}
                    value={startDate || ''}
                    placeholder={__('Start date', 'dreamlines')}
                    format={dateFormat}
                    formatDate={formatDate}
                    inputProps={{
                        readOnly: true,
                        onBlur: () => {
                            setFocusedInput(null);
                        },
                        onFocus: () => handleFocusChange('startDate'),
                    }}
                    style={{
                        backgroundImage: `url(${config.images}/common/calendar.svg)`,
                        ...getFocusStyle(DATE_TYPES.startDate),
                    }}
                    showOverlay={props.showOverlay}
                    dayPickerProps={{
                        selectedDays: [startDate, { from: startDate, to: endDate }],
                        disabledDays: { before: new Date() },
                        modifiers,
                        numberOfMonths: props.numberOfMonths,
                        onDayClick: (_, props) => {
                            if (props.disabled) {
                                return;
                            }
                            setFocusedInput(null);
                        },
                        ...dayPickerLocaleSettings,
                        navbarElement: <Navbar idEl={DATE_TYPES.startDate} />,
                    }}
                    onDayChange={(date: Date) => {
                        handleDateChange(date, DATE_TYPES.startDate);
                        setDurationFilter(date, endDate);
                    }}
                />
            </div>
            <div className="calendarEndDateWrapper">
                {endDate && (
                    <StyledXIcon onClick={() => handleResetDate(undefined, DATE_TYPES.endDate)} />
                )}
                <DayPickerInput
                    ref={endDatePickerEl}
                    value={endDate || ''}
                    placeholder={__('End date', 'dreamlines')}
                    format={dateFormat}
                    formatDate={formatDate}
                    inputProps={{
                        readOnly: true,
                        onBlur: () => {
                            setFocusedInput(null);
                        },
                        onFocus: () => handleFocusChange('endDate'),
                    }}
                    showOverlay={props.showOverlay}
                    style={{
                        backgroundImage: `url(${config.images}/common/calendar.svg)`,
                        ...getFocusStyle(DATE_TYPES.endDate),
                    }}
                    dayPickerProps={{
                        selectedDays: [startDate, { from: startDate, to: endDate }],
                        disabledDays: { before: startDate || new Date() },
                        modifiers,
                        initialMonth: startDate || new Date(),
                        numberOfMonths: props.numberOfMonths,
                        onDayClick: (_, props) => {
                            if (props.disabled) {
                                return;
                            }
                            if (props.isMobile) {
                                startDatePickerEl.current?.input.focus();
                            }

                            setFocusedInput(null);
                        },
                        ...dayPickerLocaleSettings,
                        navbarElement: <Navbar idEl={DATE_TYPES.endDate} />,
                    }}
                    onDayChange={(date: Date) => {
                        setDurationFilter(startDate, date);
                        handleDateChange(date, DATE_TYPES.endDate);
                    }}
                />
            </div>
        </div>
    );
};

Calendar.defaultProps = {
    numberOfMonths: 1,
    startDate: undefined,
    endDate: undefined,
    showOverlay: false,
    keepFocus: false,
    focusIdetifier: true,
};

export default Calendar;
